import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebsocketComponent } from './WebSocketComponent/websocket/websocket.component';

const routes: Routes = [
  // { path: "", pathMatch: 'full', redirectTo: 'patient' },
  // {
  //   path: 'patient',
  //   loadChildren: () => import('src/app/core/patient/patient/patient.module').then(m => m.PatientModule)
  // },
  {
   path:"" , component:WebsocketComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
