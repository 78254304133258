import { Injectable } from '@angular/core';
import { ChatMessageDto } from '../ChatMessageDto';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  webSocket!: WebSocket;
  chatMessages: ChatMessageDto[] = [];
  private reconnectAttempts: number = 0;
  private readonly maxReconnectAttempts: number = 5;
  private readonly reconnectInterval: number = 5000;
  private pingInterval: any;
  private readonly pingFrequency: number = 30000; // 30 seconds
  constructor() { }

  public openWebSocket() {
    this.webSocket = new WebSocket('wss://trends-commpoc-dev.myqone.com/trendpoc/chat');
    //this.webSocket = new WebSocket('ws://localhost:8080/chat');
    this.webSocket.onopen = (event) => {
      console.log('WebSocket connection established:', event);
      this.reconnectAttempts = 0; // Reset reconnect attempts
    };

    this.webSocket.onmessage = (event) => {
      const chatMessageDto = JSON.parse(event.data);
      this.chatMessages.push(chatMessageDto);
      console.log('Received message:', chatMessageDto);
    };

    this.webSocket.onclose = (event) => {
      console.log('WebSocket connection closed:', event);
      // this.reconnectWebSocket(); // Attempt reconnection
      setTimeout(() => this.openWebSocket(), this.reconnectInterval);
    };

    this.webSocket.onerror = (event) => {
      console.error('WebSocket error:', event);
      this.webSocket.close();
    };
  }

  public sendMessage(chatMessageDto: ChatMessageDto) {

    if (this.webSocket && this.webSocket.readyState === WebSocket.OPEN) {
      this.webSocket.send(JSON.stringify(chatMessageDto));
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  public closeWebSocket() {
    if (this.webSocket) {
      this.webSocket.close();
    }
  }

  // private reconnectWebSocket() {
  //   if (this.reconnectAttempts < this.maxReconnectAttempts) {
  //     this.reconnectAttempts++;
  //     console.log(`Reconnecting... Attempt ${this.reconnectAttempts}`);
  //     setTimeout(() => this.openWebSocket(), this.reconnectInterval);
  //   }else {
  //     console.error('Max reconnect attempts reached. Unable to reconnect.');
  //   }
  // }
}